import React from 'react';
import StatisticBoxComponent from '@fuww/library/src/StatisticBox';
import { DocumentNode } from 'graphql';
import { useIntl } from 'react-intl';
import withQuery, { WithDataProperties } from '../../lib/withQuery';
import messages from '../../lib/messages.mjs';
import { amountOfDays } from '../../lib/constants';

type StatisticBoxProperties = WithDataProperties & {
  data: Record<string, number>;
  fieldName: string;
  label: string;
  isCurrentData?: boolean;
};

const StatisticBox = ({
  data,
  fieldName,
  label,
  isCurrentData,
}: StatisticBoxProperties) => {
  const displayData = data[fieldName];
  const intl = useIntl();

  const displayLabel = isCurrentData
    ? intl.formatMessage(messages.now)
    : `
        ${intl.formatMessage(messages.last)}
        ${amountOfDays}
        ${intl.formatMessage(messages.days)}
      `;

  return (
    <StatisticBoxComponent
      label={label}
      displayLabel={displayLabel}
      displayData={displayData}
    />
  );
};

const StatisticBoxWithQuery = withQuery(StatisticBox);

interface StatisticBoxWithDataProperties {
  fieldName: string;
  label: string;
  query: DocumentNode;
  variables?: Record<string, unknown>;
  isCurrentData?: boolean;
}

const StatisticBoxWithData = ({
  fieldName,
  label,
  query,
  variables,
  isCurrentData = false,
}: StatisticBoxWithDataProperties) => (
  <StatisticBoxWithQuery
    errorMessage="Error loading statistic data"
    fieldName={fieldName}
    label={label}
    query={query}
    variables={variables}
    isCurrentData={isCurrentData}
  />
);

export default StatisticBoxWithData;
