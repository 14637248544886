import { Roles } from '../components/UserProvider';

const hasGlobalRole = (
  userRoles: Roles | undefined,
  requiredRole: string,
  // eslint-disable-next-line no-underscore-dangle
): boolean => Boolean(userRoles?.__global_roles__?.includes(requiredRole));

const hasRoleInAnyGroup = (
  userRoles: Roles,
  requiredRole: string,
): boolean => Object.values(userRoles).some(
  (group) => group.includes(requiredRole),
);

export {
  hasGlobalRole,
  hasRoleInAnyGroup,
};
